import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Box, Stack } from '@chakra-ui/react';
import { useStatsigClient } from '@statsig/react-bindings';
import BanksContext from '@contexts/BanksContext';
import UserContext from '@contexts/UserContext';
import UserAccessContext from '@contexts/UserAccessContext';
import { getQueryParams } from '@core/Services/Firebase/firebase';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import useBreakPoints from '@core/hooks/useBreakPoints';
import DisclaimerText from '@shared/components/DisclaimerText';
import { useZendeskAPI } from '@core/contexts/ZendeskContext';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { useCurrentWorkspace } from '@shared/hooks';
import Summary from './Summary';
import MainContent from './MainContent';
import BaselaneDisclaimer from './components/BaselaneDisclaimer';
import { disclaimerText } from './components/BaselaneDisclaimer/const/disclaimerText';
import { BaselaneErrorCard, BaselaneLimitedBankingAccessAlert } from '../Shared/components';
import { containerStyle } from './styles/nativeBanking.styles';

function NativeBankingPage() {
  const { checkGate } = useStatsigClient();
  const { isWorkspaceOwner, authorizedForBanking } = useContext(UserAccessContext);
  const { isMax768: isMobileBreakpoint } = useBreakPoints();
  const { activeWorkspaceId, collaborators } = useCurrentWorkspace();
  const {
    onboardingConnectAccount,
    setOnboardingConnectAccount,
    onboardingBaselaneAccount,
    user,
  } = useContext(UserContext);

  const {
    loading,
    error,
    hasNoBaselaneAccounts,
    hasNoConnectedAccounts,
    setAddAccountFlag,
    getKYCUrl,
    banks,
    onPlaidConfirmationMobileOpen,
    addExternalAccountSummaryDrawerRef,
    addExternalAccountDrawerMobileRef,
  } = useContext(BanksContext);

  const filteredCollaborator = collaborators?.filter((collab) => collab.email === user?.email);

  // zendesk API access
  const zendeskAPI = useZendeskAPI();

  const location = useLocation();

  const containerStyles = isMobile && {
    mt: '32px',
    m: '0',
    height: '100%',
  };

  // For onboarding tirage to trigger open components/links
  useEffect(() => {
    if (onboardingBaselaneAccount && getKYCUrl) {
      getKYCUrl();
    }

    if (banks && onboardingConnectAccount) {
      setAddAccountFlag(true);

      if (isMobile && addExternalAccountDrawerMobileRef?.current) {
        onPlaidConfirmationMobileOpen();
        addExternalAccountDrawerMobileRef.current.open();
      } else if (addExternalAccountSummaryDrawerRef?.current) {
        addExternalAccountSummaryDrawerRef.current.open();
      }
      setOnboardingConnectAccount(false);
    }

    const kycFormCompleted = getQueryParams(location, 'kycFormComplete');
    if (banks && kycFormCompleted && kycFormCompleted === 'true') {
      // check if length is 1 because when users come back to app we get new data
      if (banks?.length === 1) {
        sendSegmentEvent('any_bank_added_fe', {
          title: document.title,
        });
        // reddit tracking
        window?.rdt('track', 'AddToCart');
      }
      sendSegmentEvent('baselane_bank_app_submitted_fe', {
        title: document.title,
      });
      window?.rdt('track', 'Lead');
    }
  }, [banks, getKYCUrl]);

  useEffect(() => {
    if (isMobileBreakpoint) {
      zendeskAPI('messenger', 'hide');
    }
  }, []);

  if (loading) return <div> Loading... </div>;
  if (error) {
    if (error.message.includes('Failed to fetch')) {
      return <BaselaneErrorCard />;
    }
  }

  return (
    <Stack {...containerStyles} overflow="visible" spacing={0}>
      <Summary
        isTransfersPaymentsPageFeatureFlagged={checkGate(FEATURE_GATES.TRANSFERS_PAYMENTS_PAGE)}
      />
      <MainContent />
      <Box height="100%" overflow="visible">
        {(!isMobile || (isMobile && (!hasNoBaselaneAccounts || !hasNoConnectedAccounts))) && (
          <BaselaneDisclaimer
            containerStyle={containerStyle(false)}
            text={<DisclaimerText />}
            showPrivacyPolicy={disclaimerText.banksAndCards.showPrivacyPolicy}
          />
        )}
      </Box>
      {collaborators?.length > 1 &&
        !filteredCollaborator[0]?.isOwner &&
        filteredCollaborator.length === 1 &&
        !filteredCollaborator[0]?.authorizedForBanking &&
        !isWorkspaceOwner &&
        !authorizedForBanking &&
        activeWorkspaceId && <BaselaneLimitedBankingAccessAlert />}
    </Stack>
  );
}

export default NativeBankingPage;
