import React, { useRef, useState, useLayoutEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import {
  Flex,
  Heading,
  HStack,
  VStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Fade,
} from '@chakra-ui/react';
import { useStatsigClient } from '@statsig/react-bindings';

import SlLoader from '@core/components/Loader';
import sendSegmentEvent from '@core/utils/sendSegmentEvent';
import formatCurrency from '@core/utils/formatCurrency';
import { FEATURE_GATES } from '@core/constants/statsigKeys';
import { BaselaneButtonIcon, BaselaneErrorCard, BaselaneSwitch } from '@shared/components';
import { CATEGORIES, PRESETS } from '@shared/helpers/cashFlow.helpers';
import useTags from '@shared/hooks/useTags';
import { Icon24ChevronDown, Icon24ChevronUp } from '@icons/24px';
import { Icon16ArrowBack } from '@icons/16px';

import HighlightedRowHeader from './components/HighlightedRowHeader';
import HighlightedRowDataColumns from './components/HighlightedRowDataColumns';
import HighlightedRowTotals from './components/HighlightedRowTotals';
import StatementDownload from './components/StatementDownload';
import TransactionsWrapper from '../../TransactionsWrapper';
import {
  getSubOptionsCategories,
  getCategorySubTagIdsByTagId,
} from '../../helpers/cashflow.helpers';
import {
  cellStyle,
  dataCellStyle,
  dataHeaderCellStyle,
  rowStyle,
  headerRowStyle,
  tableStyles,
  fontStyle,
  totalFontStyle,
  headerFontStyle,
  headerTopFontStyle,
  headerTotalFontStyle,
  headerColumnStyles,
  headerColumnFontStyle,
  headerColumnTotalFontStyle,
  dataColumnStyles,
  totalsColumnStyles,
  accordionHeaderFontStyle,
  accordionHeaderCellStyle,
  accordionHeaderDataCellStyle,
  accordionHeaderRowStyle,
  accordionPanelStyle,
  pseudoBorder,
  fixedHeaderStyles,
  fixedHeaderContainerStyles,
  DATA_CELL_TOTAL_WIDTH,
  HEADER_COLUMN_WIDTH,
  TOTALS_COLUMN_MIN_WIDTH,
  TABS_PANELS_PADDING,
} from '../../styles/cashflowStatement.styles';
import '../../styles/cashflowStatement.scss';

import {
  relevantCategoriesAsColumn,
  specificallyChosenCategoriesAsColumn,
  getDataColumns,
  getPropertyUnitDataColumns,
  calculateDataColumns,
  calculatePropertyUnitDataColumns,
  getTotalsColumn,
  calculateTotalsColumn,
  calculateTotalsRow,
  getDetailsColumns,
  getPropertyDetailsColumns,
  sumOf,
  transposeData,
  getStatementData,
  generateNullData,
  findParentCategory,
} from '../../helpers/cashflow-statement.helpers';

type CashflowStatementProps = {
  loading: Boolean,
  error: Boolean,
  data: any,
  tabsRef: any,
  lastCategoryPreset?: any,
  selectedCustomCategories?: Array<any>,
  categoryOptions: any,
  filters: any,
  hasNoData: Boolean,
  dataType: String,
  cashFlowPropertiesData: Array<Object>,
  propertiesTags: Array<String>,
  unitsTags: Array<String>,
};

function CashflowStatement({
  loading,
  error,
  data,
  tabsRef,
  lastCategoryPreset,
  selectedCustomCategories,
  categoryOptions,
  filters,
  hasNoData,
  dataType,
  cashFlowPropertiesData,
  propertiesTags,
  unitsTags,
}: CashflowStatementProps) {
  if (loading || !tabsRef) return <SlLoader />;
  if (error) {
    if (error.message.includes('Failed to fetch')) {
      return <BaselaneErrorCard />;
    }
    return null;
  }
  const { checkGate } = useStatsigClient();
  const showSubCats = checkGate(FEATURE_GATES.CASHFLOW_BY_PROPERTY_UNIT);

  // need this to determine the subcategories for the clicked amount tagId
  const { categoryWithSubOptions } = useTags();
  const subOptionsCategories = useMemo(() => getSubOptionsCategories(categoryWithSubOptions), []);

  const [transactionsTableOpen, setTransactionsTableOpen] = useState(false);
  const [transactionsTableFull, setTransactionsTableFull] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const currentPreset = lastCategoryPreset?.name || PRESETS.NET_OPERATING_CASHFLOW;

  const isCustomPreset = currentPreset === PRESETS.CUSTOM;
  const isScheduleEPreset = currentPreset === PRESETS.SCHEDULE_E_CATEGORIES;
  const isNOI = currentPreset === PRESETS.NOI;

  /** All the applicable headers for the statement data requested */
  const headers =
    (isCustomPreset || isScheduleEPreset || isNOI) && selectedCustomCategories
      ? specificallyChosenCategoriesAsColumn(
          data,
          selectedCustomCategories,
          lastCategoryPreset?.uncategorized,
          CATEGORIES
        )[0]
      : relevantCategoriesAsColumn(data, categoryOptions, CATEGORIES)[0];
  const markedHeaders =
    (isCustomPreset || isScheduleEPreset || isNOI) && selectedCustomCategories
      ? specificallyChosenCategoriesAsColumn(
          data,
          selectedCustomCategories,
          lastCategoryPreset?.uncategorized,
          CATEGORIES
        )[1]
      : relevantCategoriesAsColumn(data, categoryOptions, CATEGORIES)[1];
  const getDataColumnsHelper = dataType === 'month' ? getDataColumns : getPropertyUnitDataColumns;
  const calculateDataColumnsHelper =
    dataType === 'month' ? calculateDataColumns : calculatePropertyUnitDataColumns;

  /** All the statement data, formatted for looping through in the template below  */
  const initialSections = Object.keys(headers).map((title, index) => {
    const headerColumn = showSubCats ? [...markedHeaders[title]] : [...headers[title]];
    const unmarkedHeaderColumn = [...headers[title]]; // for statements

    const dataColumns =
      isCustomPreset || isScheduleEPreset
        ? calculateDataColumnsHelper(
            index === 0,
            title,
            headers[title],
            data,
            dataType,
            cashFlowPropertiesData?.property
          )
        : getDataColumnsHelper(
            index === 0,
            title,
            headers[title],
            data,
            dataType,
            cashFlowPropertiesData?.property
          );

    const totalsColumn =
      isCustomPreset || isScheduleEPreset
        ? calculateTotalsColumn(title, [...headers[title]], data)
        : getTotalsColumn(title, [...headers[title]], data);

    return {
      title,
      table: {
        headerColumn,
        dataColumns,
        totalsColumn,
        unmarkedHeaderColumn,
      },
    };
  });

  const sections =
    initialSections.length < 1 ? generateNullData(data, categoryOptions) : initialSections;

  /** The nummber of columns to be rendered within the scrollable center of the statement */
  const columnCount = useMemo(() => sections[0]?.table?.dataColumns?.length, [
    sections[0]?.table?.dataColumns?.length,
  ]);

  /** Additional statement data totals rows. */
  const [netOperatingIncome, netOperatingCashflow, totalInflowsOutflows] =
    dataType === 'month' ? getDetailsColumns(data) : getPropertyDetailsColumns(data);

  /** This is used when Schedule E Categories or Custom is chosen */
  let calculatedTotals = [0];
  if (isCustomPreset || isScheduleEPreset) {
    calculatedTotals = calculateTotalsRow(sections);
  }

  /** The same data as statement UI, but formatted for CSV generator to consume */
  const statementData = useMemo(() => {
    return getStatementData(
      sections,
      netOperatingIncome,
      netOperatingCashflow,
      totalInflowsOutflows,
      calculatedTotals,
      currentPreset,
      dataType
    );
  }, [sections, netOperatingIncome, netOperatingCashflow, totalInflowsOutflows]);

  /** All the elements that need to scroll together when data columns are scrolled horizontally */
  const scrollables = {
    dataContainer: useRef(),
    scrollbar: useRef(),
    fixedHeader: useRef(),
  };

  /** Container surrounding the rows of the statement, following the fixed header row */
  const contentContainerRef = useRef();

  /** The indexes or labels of the (sub)accordions that are currently open */
  const [accordionIndexState, setAccordionIndexState] = useState([
    ...Array(sections.length).keys(),
  ]);
  const categoriesWithSubCategories = [];
  Object.values(markedHeaders).forEach((section) => {
    section.forEach((name) => {
      if (name.includes('+++')) categoriesWithSubCategories.push(`+++${name}`);
    });
  });
  const [subAccordions, setSubAccordions] = useState([]);

  /** Determines if statement is ready to display, to avoid visual glitches when
   * calculating dimensions/resizing
   */
  const [hideStatement, _setHideStatement] = useState(true);

  const [animateIn, setAnimateIn] = useState(false);
  /** Wraps setter to allow side effect */
  const setHideStatement = (value) => {
    if (hideStatement === true && value === false) {
      setAnimateIn(true);
    }
    _setHideStatement(value);
  };

  /** The actual width of the scrollable content container */
  const [dataContainerWidth, setDataContainerWidth] = useState(
    scrollables?.dataContainer?.current?.clientWidth
  );
  /** The width needed to fit all displayed data columns */
  const [dataColumnsWidth, setDataColumnsWidth] = useState(columnCount * DATA_CELL_TOTAL_WIDTH);
  /** The width of the entire statement */
  const [statementLayoutWidth, setStatementLayoutWidth] = useState(
    contentContainerRef?.current?.clientWidth
  );
  /** The width of the tab panel holding the statement */
  const [tabPanelWidth, setTabPanelWidth] = useState(tabsRef?.current?.clientWidth);
  /** The height of the tab panel holding the statement */
  const [tabPanelHeight, setTabPanelHeight] = useState(tabsRef?.current?.clientHeight);

  /**
   * Dynamically calculates the width of the scrollable area of statement,
   * based on how much data content there is to display.
   * Assumption: all accordion sections will have the same number of columns
   *
   * @returns A CSS px-based string describing the optimal width for the totals column.
   */
  const scrollBarWidth = useMemo(() => {
    return `${Math.round(Math.min(dataColumnsWidth, dataContainerWidth))}px`;
  }, [dataColumnsWidth, dataContainerWidth]);

  /**
   * A handler that propagates changes to an accordion across
   * to the other accordions that belong in the same row.
   *
   * Note: This is where the accordion animation is broken
   * as setting the index instantly changes the state, as such
   * the transitions are currently disabled to prevent serious visual glitches.
   */
  const handleAccordionChange = (accordionStateIndexes) => {
    setAccordionIndexState(accordionStateIndexes);
  };

  /** True if ui is short enough that the natural scrollbar shows at the bottom  */
  const [isContentTooShort, setIsContentTooShort] = useState(true);
  /** True if ui is short enough that the natural scrollbar shows at the bottom  */
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  /** True if there is not enough data content to require a scrollbar */
  const [isContentTooNarrow, setIsContentTooNarrow] = useState(true);

  /** Shows/hides the custom scrollbar, based on the flags above */
  const showPromptingScrollbar = useMemo(
    () => !isContentTooNarrow && !isContentTooShort && !isScrolledToBottom,
    [isContentTooShort, isContentTooNarrow, isScrolledToBottom]
  );

  /**
   * Dynamically calculates the width of the totals column, based
   * on the amount of data content to the left of it.
   *
   * @returns The optimal width for the totals column in pixels.
   */
  const totalsColumnWidth = useMemo(() => {
    const generatedWidth =
      tabPanelWidth - HEADER_COLUMN_WIDTH - TABS_PANELS_PADDING * 2 - dataColumnsWidth - 20;
    return Math.max(TOTALS_COLUMN_MIN_WIDTH, generatedWidth);
  }, [tabPanelWidth, dataColumnsWidth]);

  const propagateScrollLeft = (scrollLeft) => {
    Object.keys(scrollables).forEach((key) => {
      if (scrollables[key]?.current) {
        scrollables[key].current.scrollLeft = scrollLeft;
      }
    });
  };

  const handleScroll = (e) => {
    e.stopPropagation();
    e.preventDefault();
    propagateScrollLeft(e?.currentTarget?.scrollLeft);
  };

  /** Initializes the layout once data has loaded */
  useLayoutEffect(() => {
    setHideStatement(true);
    /** Calculates minimum width needed to display all columns */
    setDataColumnsWidth(columnCount * DATA_CELL_TOTAL_WIDTH);

    /** Does the actual layout initialization, once everything is ready */
    const deferLayoutPaint = () => {
      /** This is a new and more efficient API to detect
       * resizing of arbitrary elements in the DOM.
       */

      const observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          switch (entry.target) {
            case scrollables.dataContainer.current:
              setDataContainerWidth(entry.contentRect.width);
              return;
            case contentContainerRef.current:
              setStatementLayoutWidth(entry.contentRect.width);
              return;
            case tabsRef.current:
              setTabPanelWidth(entry.contentRect.width);
              setTabPanelHeight(entry.contentRect.height);
              break;
            default:
          }
        });
      });

      setIsContentTooShort(tabsRef?.current?.scrollHeight <= tabPanelHeight);
      setIsContentTooNarrow(
        scrollables?.dataContainer?.current?.scrollWidth <=
          scrollables?.dataContainer?.current?.clientWidth
      );

      observer.observe(scrollables?.dataContainer?.current, { box: 'border-box' });
      observer.observe(contentContainerRef?.current, { box: 'border-box' });
      observer.observe(tabsRef?.current, { box: 'border-box' });

      /** Runs as the page is scrolled downwards */
      const pageScrollHandler = (e) => {
        setIsScrolledToBottom(
          (tabsRef?.current?.scrollHeight || 0) - tabPanelHeight - e.target.scrollTop <= 10
        );
        propagateScrollLeft(scrollables?.dataContainer?.current?.scrollLeft);
      };

      tabsRef?.current?.addEventListener('scroll', pageScrollHandler);
      setHideStatement(false);

      return () => {
        // cleanup of handlers
        observer && observer.disconnect();
        tabsRef?.current?.removeEventListener('scroll', pageScrollHandler);
        setHideStatement(true);
      };
    };

    /**
     * Attempts to initialize layout, but if needed elements are not referenced yet,
     * will defer 1 clock tick and try again.
     */
    const checkIfStateReady = () => {
      if (scrollables?.dataContainer?.current && contentContainerRef?.current && tabsRef?.current) {
        return deferLayoutPaint();
      }
      const timer = setTimeout(checkIfStateReady, 1);
      return () => {
        clearTimeout(timer);
      };
    };

    return checkIfStateReady();
  }, [columnCount, tabPanelHeight]);

  const filenameSuffix = `-${currentPreset
    ?.toLowerCase()
    ?.split(' ')
    .join('-')
    .split('&')
    .join('and')
    .split('-(noi)')
    .join('')}`;

  const { uncategorized, ...defaultTrxFilters } = filters.filter;
  const [trxFilters, setTrxFilters] = useState({
    ...defaultTrxFilters,
    isCategorized: !uncategorized,
  });

  const handleAmountClick = (columnIndex, rowIndex, sectionIndex) => {
    let month = '';
    let year = '';
    let endMonth = '';
    let endYear = '';
    if (columnIndex === 99) {
      month = sections[0]?.table?.dataColumns[0][0].split(' ')[0];
      year = sections[0]?.table?.dataColumns[0][0].split(' ')[1];
      const columnLength = sections[0]?.table?.dataColumns?.length;
      endMonth = sections[0]?.table?.dataColumns[columnLength - 1][0].split(' ')[0];
      endYear = sections[0]?.table?.dataColumns[columnLength - 1][0].split(' ')[1];
      setSelectedMonth(null);
      setSelectedYear(`${month} ${year} - ${endMonth} ${endYear}`);
    } else {
      month = sections[0]?.table?.dataColumns[columnIndex][0].split(' ')[0];
      year = sections[0]?.table?.dataColumns[columnIndex][0].split(' ')[1];
      setSelectedMonth(month);
      setSelectedYear(year);
    }
    const categoryLabel = sections[sectionIndex]?.table?.headerColumn[rowIndex]
      .replace('___', '')
      .replace('+++', '');
    setSelectedCategory(categoryLabel);

    // Find the category item by label to get its ID
    const categoryItem = subOptionsCategories?.find((item) => item.name === categoryLabel);

    // Convert month name to number (Jan -> 1, Feb -> 2, etc)
    const monthNumber = DateTime.fromFormat(month, 'MMM').month;
    const endMonthNumber = DateTime.fromFormat(endMonth, 'MMM').month;
    const timePeriod = {
      from: DateTime.fromObject({ year: parseInt(year, 10), month: monthNumber })
        .startOf('month')
        .toISODate(),
      to: DateTime.fromObject({
        year: parseInt(endYear || year, 10),
        month: parseInt(endMonthNumber || monthNumber, 10),
      })
        .endOf('month')
        .toISODate(),
    };
    let hasCategoryId = false;
    if (categoryItem?.id && categoryItem?.id !== '0') {
      hasCategoryId = true;
    }
    setTrxFilters({
      ...trxFilters,
      from: timePeriod.from,
      to: timePeriod.to,
      tagId: hasCategoryId
        ? [categoryItem?.id, ...getCategorySubTagIdsByTagId(subOptionsCategories, categoryItem?.id)]
        : null,
      isCategorized: hasCategoryId,
    });
    setTransactionsTableOpen(true);
    setTransactionsTableFull(true);
    sendSegmentEvent('cashflow_statement_clicked_drill_down');
  };

  const handleAmountPropertyUnitClick = (columnIndex, rowIndex, sectionIndex) => {
    let propertyUnitIds = null;
    if (columnIndex !== 99) {
      propertyUnitIds = sections[0]?.table?.dataColumns[columnIndex][0].split(':::')[
        dataType === 'unit' ? 2 : 1
      ];
    }
    const categoryLabel = sections[sectionIndex]?.table?.headerColumn[rowIndex]
      .replace('___', '')
      .replace('+++', '');
    setSelectedCategory(categoryLabel);
    // Find the category item by label to get its ID
    const categoryItem = subOptionsCategories?.find((item) => item.name === categoryLabel);

    let hasCategoryId = false;
    if (categoryItem?.id && categoryItem?.id !== '0') {
      hasCategoryId = true;
    }

    if (columnIndex !== 99) {
      setTrxFilters({
        ...trxFilters,
        propertyId: dataType === 'property' ? [propertyUnitIds] : null,
        unitId: dataType === 'unit' ? [propertyUnitIds] : null,
        tagId: hasCategoryId
          ? [
              categoryItem?.id,
              ...getCategorySubTagIdsByTagId(subOptionsCategories, categoryItem?.id),
            ]
          : null,
        isCategorized: hasCategoryId,
      });
    } else {
      setTrxFilters({
        ...trxFilters,
        propertyId: filters.propertyId || propertiesTags,
        unitId: filters.unitId || unitsTags,
        tagId: hasCategoryId
          ? [
              categoryItem?.id,
              ...getCategorySubTagIdsByTagId(subOptionsCategories, categoryItem?.id),
            ]
          : null,
        isCategorized: hasCategoryId,
      });
    }
    setTransactionsTableOpen(true);
    setTransactionsTableFull(true);
    sendSegmentEvent('cashflow_statement_clicked_drill_down');
  };

  const showTransactionsTableBottom = transactionsTableOpen && !transactionsTableFull;
  return (
    <VStack height="100%" overflow="hidden">
      {hideStatement && !transactionsTableOpen && (
        <Box {...{ position: 'fixed', top: '50%', left: '50%', zIndex: '10' }}>
          <SlLoader />
        </Box>
      )}
      {!transactionsTableFull && (
        <Box
          position="relative"
          height={transactionsTableOpen ? '70%' : '100%'}
          overflowY="auto"
          marginBottom="-24px"
          maxWidth="100%"
        >
          <Fade in={animateIn}>
            <VStack
              {...{
                ...fixedHeaderContainerStyles,
                visibility: hideStatement ? 'hidden' : 'visible',
                animation: '',
                gap: 0,
              }}
            >
              <StatementDownload
                statementData={statementData}
                filenameSuffix={filenameSuffix}
                handleStatementDownloadClick={() => {
                  sendSegmentEvent('cashflow_statement_click_download_csv');
                }}
              />
              <HStack {...{ ...fixedHeaderStyles, w: `${statementLayoutWidth}px` }}>
                <VStack
                  {...{
                    ...headerColumnStyles,
                    h: '56px',
                    ...pseudoBorder('right', 0),
                    gap: 0,
                  }}
                >
                  <Table {...{ ...tableStyles(true), w: '100%' }}>
                    <Thead>
                      <Tr {...headerRowStyle} pt="16px">
                        {showSubCats ? (
                          <BaselaneSwitch
                            label="Show all sub-categories"
                            isChecked={subAccordions.length === categoriesWithSubCategories.length}
                            onChange={() => {
                              if (subAccordions.length !== categoriesWithSubCategories.length) {
                                setSubAccordions(categoriesWithSubCategories);
                              } else {
                                setSubAccordions([]);
                              }
                            }}
                            mb="0"
                          />
                        ) : null}
                      </Tr>
                    </Thead>
                  </Table>
                </VStack>
                <VStack
                  {...{
                    ...dataColumnStyles,
                    overflowX: 'hidden',
                  }}
                  ref={scrollables.fixedHeader}
                  onScroll={handleScroll}
                >
                  <Table {...tableStyles(true)}>
                    <Thead>
                      <Tr {...headerRowStyle}>
                        {sections[0] &&
                          sections[0]?.table?.dataColumns?.map((column) => {
                            return (
                              <Th key={uuidv4()} {...dataHeaderCellStyle}>
                                <Text key={uuidv4()} {...headerTopFontStyle}>
                                  {column[0].split(':::')[0]}
                                </Text>
                                {dataType === 'unit' && (
                                  <Text key={uuidv4()} {...headerTopFontStyle} paddingTop="0">
                                    {column[0].split(':::')[1]}
                                  </Text>
                                )}
                              </Th>
                            );
                          })}
                      </Tr>
                    </Thead>
                  </Table>
                </VStack>
                <VStack
                  {...{
                    ...totalsColumnStyles(totalsColumnWidth),
                    h: '56px',
                    ...pseudoBorder('left', 0),
                  }}
                >
                  <Table {...tableStyles(true)}>
                    <Thead>
                      <Tr {...headerRowStyle}>
                        <Th {...{ ...dataHeaderCellStyle, p: '0' }}>
                          <Text {...headerTotalFontStyle} fontWeight="normal">
                            Total
                          </Text>
                        </Th>
                      </Tr>
                    </Thead>
                  </Table>
                </VStack>
              </HStack>
            </VStack>

            <HStack
              ref={contentContainerRef}
              {...{
                alignItems: 'flex-start',
                mb: '0px',
                pt: '56px',
                visibility: hideStatement ? 'hidden' : 'visible',
                gap: 0,
              }}
            >
              <Accordion
                as={VStack}
                id="headerColumn"
                allowMultiple
                reduceMotion
                index={accordionIndexState}
                {...{ ...headerColumnStyles, ...pseudoBorder('right', 0) }}
                onChange={handleAccordionChange}
              >
                {sections.map((section, sectionIndex) => {
                  return (
                    <React.Fragment key={uuidv4()}>
                      <AccordionItem as={Table} variant="simple" {...tableStyles()}>
                        <Thead>
                          {sectionIndex === 0 && (
                            <Tr {...headerRowStyle}>
                              <Th {...dataHeaderCellStyle}>
                                <Text {...headerFontStyle}> </Text>
                              </Th>
                            </Tr>
                          )}
                          <Tr {...{ ...accordionHeaderRowStyle, borderRadius: '4px 0 0 4px' }}>
                            <Th {...accordionHeaderCellStyle}>
                              <AccordionButton fontSize="xs" p="0 14px 0 0">
                                <HStack
                                  as="span"
                                  {...{ justifyContent: 'space-between', w: '100%', gap: 0 }}
                                >
                                  <Text {...{ ...accordionHeaderFontStyle, ml: '0px !important' }}>
                                    {section?.title}
                                  </Text>
                                </HStack>
                                <AccordionIcon height="19px" width="19px" />
                              </AccordionButton>
                            </Th>
                          </Tr>
                        </Thead>
                        <AccordionPanel as={Tbody} {...accordionPanelStyle}>
                          {section?.table?.headerColumn?.map((row, rowIndex) => {
                            const categories = Object.values(showSubCats ? markedHeaders : headers)[
                              sectionIndex
                            ];
                            const isSubCategory =
                              categories && categories[rowIndex].includes('___');
                            const isParentExpanded = isSubCategory
                              ? subAccordions.includes(
                                  `+++${findParentCategory(categories, rowIndex)}+++`
                                )
                              : true;
                            const hideSubCategory = isSubCategory && !isParentExpanded;
                            // hide if flag is off and this row is a subcategory, or if parent is not expanded
                            return hideSubCategory ? null : (
                              <Tr key={uuidv4()} {...rowStyle}>
                                <Th
                                  key={uuidv4()}
                                  {...cellStyle}
                                  cursor={
                                    showSubCats && row.includes('+++') ? 'pointer' : 'default'
                                  }
                                  onClick={
                                    showSubCats
                                      ? () => {
                                          if (row.includes('+++')) {
                                            const categoryLabel = `+++${row}`;
                                            setSubAccordions((prev) =>
                                              prev.includes(categoryLabel)
                                                ? prev.filter((label) => label !== categoryLabel)
                                                : [...prev, categoryLabel]
                                            );
                                          }
                                        }
                                      : () => {}
                                  }
                                >
                                  <HStack justifyContent="space-between" pr="12px">
                                    <Box>
                                      {rowIndex ===
                                      (section?.table?.headerColumn.length || 0) - 1 ? (
                                        <Text {...headerColumnTotalFontStyle}>
                                          {row}
                                        </Text> /* Totals */
                                      ) : (
                                        <Text {...headerColumnFontStyle(row.includes('___'))}>
                                          {row.replace('+++', '').replace('___', '')}
                                        </Text> /* Categories */
                                      )}
                                    </Box>
                                    {showSubCats && row.includes('+++') && (
                                      <Box>
                                        {subAccordions.includes(`+++${row}`) ? (
                                          <Icon24ChevronDown />
                                        ) : (
                                          <Icon24ChevronUp />
                                        )}
                                      </Box>
                                    )}
                                  </HStack>
                                </Th>
                              </Tr>
                            );
                          })}
                        </AccordionPanel>
                      </AccordionItem>
                      {section?.title === 'Operating Expenses' &&
                        (currentPreset === PRESETS.NET_OPERATING_CASHFLOW ||
                          currentPreset === PRESETS.TOTAL_INFLOWS_OUTFLOWS) && (
                          <HighlightedRowHeader title={PRESETS.NOI} />
                        )}
                      {section?.title === 'Uncategorized' &&
                        currentPreset === PRESETS.TOTAL_INFLOWS_OUTFLOWS && (
                          <HighlightedRowHeader title={PRESETS.NET_OPERATING_CASHFLOW} />
                        )}
                      {sectionIndex === sections.length - 1 && currentPreset && (
                        <HighlightedRowHeader
                          title={currentPreset === PRESETS.CUSTOM ? 'Total' : currentPreset}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </Accordion>
              <Accordion
                as={VStack}
                id="dataColumn"
                allowMultiple
                reduceMotion
                index={accordionIndexState}
                {...dataColumnStyles}
                ref={scrollables.dataContainer}
                onScroll={handleScroll}
                onChange={handleAccordionChange}
              >
                {/* Note: places a scrollbar at the bottom of the data column
                    so that the user knows there is content to horizontally scroll to */}
                <Box
                  id="promptingScrollbar"
                  ref={scrollables.scrollbar}
                  onScroll={handleScroll}
                  {...{
                    w: `${dataContainerWidth}px`,
                    visibility: showPromptingScrollbar ? 'visible' : 'hidden',
                  }}
                >
                  <Box
                    id="dummyContent"
                    {...{
                      width: scrollBarWidth,
                      height: '1px',
                    }}
                  />
                </Box>
                {sections.map((section, sectionIndex) => {
                  const columns = section?.table?.dataColumns;
                  const rows = transposeData(columns);
                  if (sectionIndex === 0) {
                    rows.shift(); // this is omitting the header row on the first table- already output above
                  }
                  return (
                    <React.Fragment key={uuidv4()}>
                      <AccordionItem as={Table} variant="simple" {...tableStyles()} key={uuidv4()}>
                        {({ isExpanded }) => (
                          <>
                            <Thead>
                              {sectionIndex === 0 && (
                                <Tr {...headerRowStyle}>
                                  {columns?.map((column) => {
                                    return (
                                      <Th key={uuidv4()} {...dataHeaderCellStyle}>
                                        <Text {...headerFontStyle}>{column}</Text>
                                      </Th>
                                    );
                                  })}
                                </Tr>
                              )}
                              <AccordionButton
                                as={Tr}
                                {...{
                                  ...accordionHeaderRowStyle,
                                  cursor: 'pointer',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {columns?.map((column, columnIndex) => {
                                  return (
                                    <Th key={uuidv4()} {...accordionHeaderDataCellStyle}>
                                      {isExpanded ? (
                                        <Text {...accordionHeaderFontStyle}>&nbsp;</Text>
                                      ) : (
                                        <Text
                                          {...{ ...totalFontStyle, lineHeight: '48px !important' }}
                                        >
                                          {
                                            formatCurrency(rows[rows.length - 1][columnIndex])
                                              .rounded
                                          }
                                        </Text>
                                      )}
                                    </Th>
                                  );
                                })}
                              </AccordionButton>
                            </Thead>
                            <AccordionPanel as={Tbody} {...accordionPanelStyle}>
                              {rows?.map((row, rowIndex) => {
                                const categories = Object.values(
                                  showSubCats ? markedHeaders : headers
                                )[sectionIndex];
                                const isSubCategory =
                                  categories && categories[rowIndex].includes('___');
                                const isParentExpanded = isSubCategory
                                  ? subAccordions.includes(
                                      `+++${findParentCategory(categories, rowIndex)}+++`
                                    )
                                  : true;
                                const hideSubCategory = isSubCategory && !isParentExpanded;
                                // hide if flag is off and this row is a subcategory
                                return hideSubCategory ? null : (
                                  <Tr key={uuidv4()} {...rowStyle}>
                                    {row?.map((val, columnIndex) => {
                                      return (
                                        <Td key={uuidv4()} {...dataCellStyle}>
                                          {rowIndex !== rows.length - 1 ? (
                                            <Text
                                              {...fontStyle}
                                              onClick={() =>
                                                dataType === 'month'
                                                  ? handleAmountClick(
                                                      columnIndex,
                                                      rowIndex,
                                                      sectionIndex
                                                    )
                                                  : handleAmountPropertyUnitClick(
                                                      columnIndex,
                                                      rowIndex,
                                                      sectionIndex
                                                    )
                                              }
                                              cursor="pointer"
                                              _hover={{ color: 'gray.400' }}
                                            >
                                              {formatCurrency(val).rounded}
                                            </Text>
                                          ) : (
                                            <Text {...totalFontStyle}>
                                              {formatCurrency(val).rounded}
                                            </Text>
                                          )}
                                        </Td>
                                      );
                                    })}
                                  </Tr>
                                );
                              })}
                            </AccordionPanel>
                          </>
                        )}
                      </AccordionItem>
                      {section?.title === 'Operating Expenses' &&
                        (currentPreset === PRESETS.NET_OPERATING_CASHFLOW ||
                          currentPreset === PRESETS.TOTAL_INFLOWS_OUTFLOWS) && (
                          <HighlightedRowDataColumns
                            columns={netOperatingIncome}
                            scrollBarWidth={scrollBarWidth}
                          />
                        )}
                      {section?.title === 'Uncategorized' &&
                        currentPreset === PRESETS.TOTAL_INFLOWS_OUTFLOWS && (
                          <HighlightedRowDataColumns
                            columns={netOperatingCashflow}
                            scrollBarWidth={scrollBarWidth}
                          />
                        )}
                      {sectionIndex === sections.length - 1 && (
                        <>
                          {currentPreset === PRESETS.NOI && (
                            <HighlightedRowDataColumns
                              columns={netOperatingIncome}
                              scrollBarWidth={scrollBarWidth}
                            />
                          )}
                          {currentPreset === PRESETS.NET_OPERATING_CASHFLOW && (
                            <HighlightedRowDataColumns
                              columns={netOperatingCashflow}
                              scrollBarWidth={scrollBarWidth}
                            />
                          )}
                          {currentPreset === PRESETS.TOTAL_INFLOWS_OUTFLOWS && (
                            <HighlightedRowDataColumns
                              columns={totalInflowsOutflows}
                              scrollBarWidth={scrollBarWidth}
                            />
                          )}
                          {currentPreset === PRESETS.SCHEDULE_E_CATEGORIES && (
                            <HighlightedRowDataColumns
                              columns={calculatedTotals}
                              scrollBarWidth={scrollBarWidth}
                            />
                          )}
                          {currentPreset === PRESETS.CUSTOM && (
                            <HighlightedRowDataColumns
                              columns={calculatedTotals}
                              scrollBarWidth={scrollBarWidth}
                            />
                          )}
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
              </Accordion>
              <Accordion
                as={VStack}
                id="totalsColumn"
                allowMultiple
                reduceMotion
                index={accordionIndexState}
                {...{
                  ...totalsColumnStyles(totalsColumnWidth),
                  ...pseudoBorder('left', 0),
                }}
                onChange={handleAccordionChange}
              >
                {sections.map((section, sectionIndex) => {
                  return (
                    <React.Fragment key={uuidv4()}>
                      <AccordionItem as={Table} variant="simple" {...tableStyles()} key={uuidv4()}>
                        {({ isExpanded }) => (
                          <>
                            <Thead>
                              {sectionIndex === 0 && (
                                <Tr {...headerRowStyle}>
                                  <Th {...dataHeaderCellStyle}>
                                    <Text {...headerTotalFontStyle}>Total</Text>
                                  </Th>
                                </Tr>
                              )}
                              <AccordionButton
                                as={Tr}
                                {...{
                                  ...accordionHeaderRowStyle,
                                  cursor: 'pointer',
                                  borderRadius: '0 4px 4px 0',
                                }}
                              >
                                <Th {...accordionHeaderCellStyle}>
                                  {isExpanded ? (
                                    <Text {...accordionHeaderFontStyle}>&nbsp;</Text>
                                  ) : (
                                    <Text
                                      {...{
                                        ...totalFontStyle,
                                        lineHeight: '48px !important',
                                      }}
                                    >
                                      {
                                        formatCurrency(
                                          section?.table?.totalsColumn[
                                            (section?.table?.totalsColumn.length || 0) - 1
                                          ]
                                        ).rounded
                                      }
                                    </Text>
                                  )}
                                </Th>
                              </AccordionButton>
                            </Thead>
                            <AccordionPanel as={Tbody} {...accordionPanelStyle}>
                              {section?.table?.totalsColumn?.map((total, rowIndex) => {
                                const isNotLastRow =
                                  rowIndex !== (section?.table?.headerColumn.length || 0) - 1;
                                const categories = Object.values(
                                  showSubCats ? markedHeaders : headers
                                )[sectionIndex];
                                const isSubCategory =
                                  categories && categories[rowIndex].includes('___');
                                const isParentExpanded = isSubCategory
                                  ? subAccordions.includes(
                                      `+++${findParentCategory(categories, rowIndex)}+++`
                                    )
                                  : true;
                                const hideSubCategory = isSubCategory && !isParentExpanded;
                                // hide if flag is off and this row is a subcategory
                                return hideSubCategory ? null : (
                                  <Tr key={uuidv4()} {...rowStyle}>
                                    <Td key={uuidv4()} {...cellStyle}>
                                      {isNotLastRow ? (
                                        <Text
                                          {...fontStyle}
                                          cursor="pointer"
                                          onClick={() =>
                                            dataType === 'month'
                                              ? handleAmountClick(99, rowIndex, sectionIndex)
                                              : handleAmountPropertyUnitClick(
                                                  99,
                                                  rowIndex,
                                                  sectionIndex
                                                )
                                          }
                                        >
                                          {formatCurrency(total).rounded}
                                        </Text>
                                      ) : (
                                        <Text {...totalFontStyle} py="10px">
                                          {formatCurrency(total).rounded}
                                        </Text>
                                      )}
                                    </Td>
                                  </Tr>
                                );
                              })}
                            </AccordionPanel>
                          </>
                        )}
                      </AccordionItem>
                      {section?.title === 'Operating Expenses' &&
                        (currentPreset === PRESETS.NET_OPERATING_CASHFLOW ||
                          currentPreset === PRESETS.TOTAL_INFLOWS_OUTFLOWS) && (
                          <HighlightedRowTotals total={sumOf(netOperatingIncome)} />
                        )}
                      {section?.title === 'Uncategorized' &&
                        currentPreset === PRESETS.TOTAL_INFLOWS_OUTFLOWS && (
                          <HighlightedRowTotals total={sumOf(netOperatingCashflow)} />
                        )}
                      {sectionIndex === sections.length - 1 && (
                        <>
                          {currentPreset === PRESETS.NOI && (
                            <HighlightedRowTotals total={sumOf(netOperatingIncome)} />
                          )}
                          {currentPreset === PRESETS.NET_OPERATING_CASHFLOW && (
                            <HighlightedRowTotals total={sumOf(netOperatingCashflow)} />
                          )}
                          {currentPreset === PRESETS.TOTAL_INFLOWS_OUTFLOWS && (
                            <HighlightedRowTotals total={sumOf(totalInflowsOutflows)} />
                          )}
                          {currentPreset === PRESETS.SCHEDULE_E_CATEGORIES && (
                            <HighlightedRowTotals total={sumOf(calculatedTotals)} />
                          )}
                          {currentPreset === PRESETS.CUSTOM && (
                            <HighlightedRowTotals total={sumOf(calculatedTotals)} />
                          )}
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
              </Accordion>
            </HStack>
          </Fade>
        </Box>
      )}
      {showTransactionsTableBottom && (
        <Box
          position="relative"
          height="24px"
          width="100%"
          zIndex="2"
          top="0"
          left="0"
          right="0"
          backgroundImage="linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))"
        />
      )}
      {showTransactionsTableBottom && (
        <Flex
          justifyContent="flex-end"
          width="100%"
          position="relative"
          top="12px"
          zIndex="2"
          backgroundColor="brand.neutral.100"
          borderTop="1px solid"
          borderBottom="1px solid"
          borderColor="brand.neutral.200"
        >
          <BaselaneButtonIcon
            icon={<Icon24ChevronDown />}
            onClick={() => setTransactionsTableOpen(!transactionsTableOpen)}
          />
          <BaselaneButtonIcon
            icon={<Icon24ChevronUp />}
            onClick={() => setTransactionsTableFull(!transactionsTableFull)}
          />
        </Flex>
      )}
      {showTransactionsTableBottom && (
        <Box
          position="relative"
          height="24px"
          width="100%"
          zIndex="2"
          bottom="-16px"
          left="0"
          right="0"
          backgroundImage="linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1))"
        />
      )}
      <Box
        height={transactionsTableFull ? '100%' : '30%'}
        display={transactionsTableOpen ? 'block' : 'none'}
        overflowY="auto"
        position={transactionsTableFull ? 'absolute' : 'relative'}
        top={transactionsTableFull ? '0' : '-28px'}
        left="0"
        right="0"
      >
        {transactionsTableFull && (
          <HStack
            width="100%"
            justifyContent="flex-start"
            gap={3}
            position="fixed"
            zIndex="2"
            background="white"
            paddingBottom="8px"
          >
            <BaselaneButtonIcon
              icon={<Icon16ArrowBack />}
              onClick={() => {
                setTransactionsTableOpen(false);
                setTransactionsTableFull(false);
              }}
              palette="neutral"
              size="lg"
              variant="outline"
            />
            <VStack alignItems="flex-start" gap={0} width="100%" backgroundColor="white">
              <Heading size="headline-lg">{selectedCategory}</Heading>
              <Text textStyle="sm">
                {selectedMonth} {selectedYear}
              </Text>
            </VStack>
          </HStack>
        )}
        {transactionsTableFull && (
          <Box
            position="fixed"
            height="24px"
            width="100%"
            zIndex="2"
            top="144px"
            backgroundImage="linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1))"
          />
        )}
        <Box position="relative" top={transactionsTableFull ? '48px' : '0'}>
          <TransactionsWrapper trxFilters={trxFilters} hasNoData={hasNoData} isTableOnly />
        </Box>
      </Box>
    </VStack>
  );
}

CashflowStatement.defaultProps = {
  lastCategoryPreset: null,
  selectedCustomCategories: null,
};

export default CashflowStatement;
