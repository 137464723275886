// @flow
import React, { useRef, useState, useEffect } from 'react';
import {
  CartesianGrid,
  XAxis,
  Bar,
  ResponsiveContainer,
  YAxis,
  Tooltip,
  Line,
  ComposedChart,
} from 'recharts';

import { v4 as uuidv4 } from 'uuid';
import { Box, Flex, VStack, useDimensions, useMediaQuery } from '@chakra-ui/react';
import CurrencyText from '@shared/components/CurrencyText';
import isNotNil from '@core/utils/validators/isNotNil';
import { tooltipStyles } from '@shared/components/BaselaneTooltip/styles/tooltip.style';
import customTheme from '@core/theme';

import useBreakPoints from '@core/hooks/useBreakPoints';
import { minWidth } from '@core/hooks/useBreakPoints/helpers/breakpoints.helpers';
import type { BarChartPropertyUnitDataSet } from '../../../CashFlow/types';
import {
  graphicsDefaultColors,
  scrollingContainerStyle,
  xTickStyle,
} from './styles/CashFlowCompositeChart.styles';
import type { CashFlowCompositeChartConfig } from './index';
import { CustomBar, CustomDot, CustomYTick, DynamicBar, SharpSVGGradientFill } from './index';

type CustomTooltipProps = {
  active: Object,
  payload: Array,
  graphicsColors: Object,
  dataType: String,
};

const CustomTooltip = ({ active, payload, graphicsColors, dataType }: CustomTooltipProps) => {
  if (active && payload) {
    const propertyUnit = payload[0]?.payload?.propertyUnitLabelObject;

    // here we have to differentiate between property and unit
    // "property" or "property-unit"
    const propertyUnitLabel = propertyUnit.unitName;
    const propertyLabel = propertyUnit.propertyName;
    const values = [];

    payload.forEach((item) => {
      const { payload: payloadObject, name } = item;
      values.push({
        amount: payloadObject[name],
        color: graphicsColors[name],
      });
    });
    return (
      <Flex {...tooltipStyles}>
        <VStack style={{ textAlign: 'left', alignItems: 'flex-start' }}>
          <p>{propertyLabel}</p>
          {dataType === 'unit' && <p>{propertyUnitLabel}</p>}
        </VStack>
        <Box ml="10px">
          {values.map((value) =>
            values.length > 1 ? (
              <Flex key={uuidv4()} alignItems="center">
                <Box
                  w="8px"
                  h="8px"
                  background={value.color}
                  borderRadius="50%"
                  m="0 4px 4px 4px"
                />
                <CurrencyText
                  key={uuidv4()}
                  isRounded={false}
                  textStyle="sm"
                  color="brand.neutral.white"
                  amount={value.amount}
                />
              </Flex>
            ) : (
              <CurrencyText
                key={uuidv4()}
                isRounded={false}
                textStyle="sm"
                color="brand.neutral.white"
                amount={value.amount}
              />
            )
          )}
        </Box>
      </Flex>
    );
  }
  return null;
};

function CustomXTickPropertyUnit({ x, y, payload }: any) {
  const [property, unit] = payload.value.split(':::');
  const {
    isMin768,
    isMin899,
    isMin1150,
    isMin1440,
    isLargerThan1537,
    isLargerThan1280,
  } = useBreakPoints();
  const axisTransform = isMin768
    ? `translate(${x} ${y + 8})`
    : `translate(${x} ${y + 8}) rotate(-90 0 0)`;
  let stringLength = 5;
  if (isMin768) stringLength = 8;
  if (isMin899) stringLength = 10;
  if (isMin1150) stringLength = 16;
  if (isLargerThan1280) stringLength = 18;
  if (isMin1440) stringLength = 22;
  if (isLargerThan1537) stringLength = 25;
  let formattedProperty = property;
  let formattedUnit = unit;
  if (property.length > stringLength) formattedProperty = `${property.slice(0, stringLength)}...`;
  if (unit?.length > stringLength) formattedUnit = `${unit.slice(0, stringLength)}...`;
  return (
    <g transform={axisTransform}>
      <text style={xTickStyle(!isMin768)}>{formattedProperty}</text>
      <text dy="1.2em" style={xTickStyle(!isMin768)}>
        {formattedUnit}
      </text>
    </g>
  );
}

type CashFlowCompositeChartByPropertyUnitProps = {
  dataset: Array<BarChartPropertyUnitDataSet>,
  handleSubFiltersUpdate?: Function,
  filters: any,
  config: CashFlowCompositeChartConfig,
  isZero?: boolean,
  maxNumber?: number,
  dataPointsInViewport?: number,
  isDashboardWidget?: boolean,
  dataType?: String,
  onSelectPropertyUnit?: Function,
};

// set DEBUG to true to console.debug() most pertinent data
const DEBUG = false;
function CashFlowCompositeChartByPropertyUnit({
  dataset,
  handleSubFiltersUpdate = () => {},
  filters,
  config,
  isZero = false,
  maxNumber = 0,
  dataPointsInViewport = 6,
  isDashboardWidget = false,
  dataType,
  onSelectPropertyUnit,
  ...rest
}: CashFlowCompositeChartByPropertyUnitProps): any {
  const graphicsColors = {
    [config.values.barOne]: config.colors?.barOne || graphicsDefaultColors.barOne,
    [config.values.barTwo]: config.colors?.barTwo || graphicsDefaultColors.barTwo,
    [config.values.lineOne]: config.colors?.lineOne || graphicsDefaultColors.lineOne,
  };

  const scrollingContainerRef = useRef();

  const dimensions = useDimensions(scrollingContainerRef, true);

  const [percentage, setCurrentPercentage] = useState(0);

  const dataPointCount = Math.min(dataPointsInViewport, dataset.length);

  // if there is only one month, pre-select it,
  // otherwise use null
  const [currentPropertyUnit, setCurrentPropertyUnit] = useState(
    dataPointCount === 1 ? dataset[0].discriminator : null
  );

  useEffect(() => {
    setCurrentPropertyUnit(dataPointCount === 1 ? dataset[0].discriminator : null);
  }, [filters]);

  const chartHeight = '100%';
  const chartWidth = ((dimensions?.paddingBox?.width || 0) / dataPointCount) * dataset.length || 0;
  const gradientIncrement = 100 / (dataset.length - 1);
  const highlightGradientId = `lineHighlight-${uuidv4()}`;

  const [isAnimationActive, setIsAnimationActive] = useState(true);

  const [isMin899] = useMediaQuery(minWidth('lg'), { ssr: false });

  if (DEBUG) {
    console.debug(CashFlowCompositeChartByPropertyUnit.name, {
      dataset,
      config,
      graphicsColors,
      dataPointCount,
      currentPropertyUnit,
      dimensions,
      chartHeight,
      chartWidth,
      percentage,
      gradientIncrement,
      highlightGradientId,
    });
  }

  function handleChartClick(event) {
    // prevents non-interactive element clicks from altering the chart view
    if (!event || event === {}) return;

    let filterForProperty = true;
    const clickedPropertyUnit =
      event?.activePayload && event?.activePayload[0]?.payload.discriminator;
    if (clickedPropertyUnit?.includes('_')) {
      filterForProperty = false;
    }

    if (isNotNil(clickedPropertyUnit)) {
      const { from, to } = filters.filter;

      // Flow issue cannot resolve the isNotNil check
      handleSubFiltersUpdate(
        filterForProperty
          ? {
              propertyId: [clickedPropertyUnit],
              from,
              to,
              dateSelected: {
                date: null,
                dateFormatted: null,
              },
              unitId: null,
            }
          : {
              unitId: [clickedPropertyUnit.split('_')[1]],
              from,
              to,
              dateSelected: {
                date: null,
                dateFormatted: null,
              },
              propertyId: null,
            }
      );
    }

    if (clickedPropertyUnit !== currentPropertyUnit) {
      setIsAnimationActive(false);
      setCurrentPropertyUnit(clickedPropertyUnit);
      onSelectPropertyUnit(null);
      setTimeout(() => onSelectPropertyUnit(clickedPropertyUnit), 10);
      const index = dataset.findIndex(
        (item) => item.discriminator?.toString() === clickedPropertyUnit?.toString()
      );
      setCurrentPercentage((1 - (dataset.length - index - 1) / (dataset.length - 1)) * 100);
    } else {
      // toggles back to the default state
      setCurrentPropertyUnit(null);
      onSelectPropertyUnit(null);
      const { from, to, propertyId, unitId } = filters.filter;
      handleSubFiltersUpdate({
        from,
        to,
        dateSelected: {
          date: null,
          dateFormatted: null,
        },
        propertyId,
        unitId,
      });
    }
  }

  function domain() {
    if (maxNumber <= 0 || maxNumber === null) {
      return ['auto', 0];
    }
    return [0, 'auto'];
  }

  useEffect(() => {
    handleSubFiltersUpdate({});
  }, [dataType]);

  return (
    <Box id="scrolling-container-ref" ref={scrollingContainerRef} {...scrollingContainerStyle}>
      <ResponsiveContainer width={chartWidth} height={chartHeight} minHeight={168} {...rest}>
        <ComposedChart
          data={dataset}
          barGap={0}
          barCategoryGap="8px"
          margin={{
            top: 20,
            right: 0,
            left: 20,
            bottom: 0, // account for xaxis labels
          }}
          cursor="pointer"
          onClick={isDashboardWidget ? () => {} : handleChartClick}
        >
          <defs>
            <SharpSVGGradientFill
              id={highlightGradientId}
              color={customTheme.colors.brand.neutral['500']}
              highlightColor={customTheme.colors.red['500A']}
              highlightStartPercentage={
                currentPropertyUnit && percentage ? percentage - gradientIncrement : 0
              }
              highlightEndPercentage={currentPropertyUnit && percentage ? percentage : 100}
            />
          </defs>
          <CartesianGrid vertical={false} stroke={customTheme.colors.brand.darkBlue['100']} />
          {config.values.barTwo === '' ? (
            <Bar
              dataKey={config.values.barOne}
              barSize={isDashboardWidget ? 16 : 32}
              shape={<CustomBar graphicsColors={graphicsColors} config={config} />}
            />
          ) : (
            [
              <Bar
                key={`barOne-${uuidv4()}`}
                dataKey={config.values.barOne}
                barSize={isDashboardWidget ? 16 : 32}
                radius={[2, 2, 0, 0]}
                fill={graphicsColors[config.values.barOne]}
                shape={
                  <DynamicBar
                    isValueOne
                    {...{ currentItem: currentPropertyUnit, graphicsColors, config }}
                  />
                }
                isAnimationActive={isAnimationActive}
              />,
              <Bar
                key={`barTwo-${uuidv4()}`}
                dataKey={config.values.barTwo}
                barSize={isDashboardWidget ? 16 : 32}
                radius={[2, 2, 0, 0]}
                fill={graphicsColors[config.values.barTwo]}
                shape={
                  <DynamicBar
                    isValueOne={false}
                    {...{ currentItem: currentPropertyUnit, graphicsColors, config }}
                  />
                }
                isAnimationActive={isAnimationActive}
              />,
            ]
          )}
          <XAxis
            orientation="bottom"
            dataKey="propertyUnitLabel"
            axisLine={{ stroke: customTheme.colors.brand.darkBlue['100'] }}
            tickLine={false}
            tick={<CustomXTickPropertyUnit />}
            padding="gap"
            interval={0}
          />
          <Line
            key={`lineOne-${uuidv4()}`}
            type="linear"
            dataKey={config.values.lineOne}
            isAnimationActive={isAnimationActive}
            onAnimationEnd={() => {
              setIsAnimationActive(false);
            }}
            dot={
              <CustomDot
                currentItem={currentPropertyUnit}
                stroke={customTheme.colors.brand.darkBlue['100']}
                fill={customTheme.colors.brand.neutral['600']}
                highlightFill={customTheme.colors.red['500A']}
                strokeWidth={isMin899 ? 2 : 1}
                radius={isMin899 ? 4 : 2}
              />
            }
            activeDot={
              <CustomDot
                currentItem={currentPropertyUnit}
                stroke={customTheme.colors.brand.darkBlue['100']}
                fill={customTheme.colors.brand.neutral['600']}
                highlightFill={customTheme.colors.red['500A']}
                strokeWidth={isMin899 ? 2 : 1}
                radius={isMin899 ? 4 : 2}
              />
            }
            stroke={`url(#${highlightGradientId})`}
            strokeWidth="0.5"
            strokeDasharray="2 1"
          />
          {isZero ? (
            <YAxis
              interval={0}
              tickCount={0}
              tickLine={false}
              axisLine={false}
              ticks={[0]}
              tick={<CustomYTick />}
            />
          ) : (
            <YAxis
              interval={0}
              tickCount={isDashboardWidget ? 4 : 6}
              type="number"
              domain={domain()}
              tickLine={false}
              axisLine={false}
              tick={<CustomYTick />}
              height={120}
            />
          )}
          <Tooltip
            content={<CustomTooltip graphicsColors={graphicsColors} dataType={dataType} />}
            cursor={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default CashFlowCompositeChartByPropertyUnit;
