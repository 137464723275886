import { isNumeric } from '@chakra-ui/utils';
import { formatDate } from '@core/utils/formatDate';
import formatCurrency from '@core/utils/formatCurrency';
import { PRESETS } from '@shared/helpers/cashFlow.helpers';

/**
 * Calculates the sum of the elements in an array.
 *
 * @param {Array} dataArray Numbers to sum up.
 *
 * @return The sum of all the elements in the array.
 */
export const sumOf = (dataArray: Number[]) => {
  return dataArray.length === 0 ? 0 : dataArray?.reduce((prev, current) => prev + current);
};

/**
 * Sorts an object's keys (doesn't look at sub-objects)
 * to match the order of the supplied second parameter.
 * Any keys that don't exist in both are ignored.
 *
 * @param {Array} data source object to process.
 * @param {Array} desiredSortOrder template object to match.
 *
 * @return Object with keys sorted to match the template.
 */
export const sortObjectToMatch = (data, desiredSortOrder) => {
  const sorted = {};
  Object.keys(desiredSortOrder).forEach((key) => {
    const name = desiredSortOrder[key];
    if (data[name]) {
      sorted[name] = data[name];
    }
  });

  return sorted;
};

/**
 * Generates an object that represents every category and subcategory
 * that has at least one transaction.
 *
 * @param {Object} data Statement data. Table. First column (row headers)
 * @param {Object} categoryOptions Array describing all possible categories, and the subcategories contained within.
 * @param {Array} desiredSortOrder Template object to match key order with.
 */
export const relevantCategoriesAsColumn = (
  { cashFlow: { byCategory } },
  categoryOptions,
  desiredSortOrder
) => {
  const column = {};
  const markedColumn = {}; // mark for categories subcategories accordions
  categoryOptions.forEach((category) => {
    column[category.title] = null;
    markedColumn[category.title] = null;
  });

  byCategory.forEach((category) => {
    if (category.subCategories.length > 0) {
      column[category.name] = [];
      markedColumn[category.name] = [];
      category.subCategories.forEach((subCategory) => {
        // for each subCategory push the name, then push subSubCategories, if any
        if (subCategory.subCategories?.length > 0) {
          column[category.name].push(`${subCategory.name}`);
          markedColumn[category.name].push(`${subCategory.name}+++`);
          subCategory.subCategories.forEach((subSubCategory) => {
            column[category.name].push(`${subSubCategory.name}`);
            markedColumn[category.name].push(`___${subSubCategory.name}`);
          });
        } else {
          column[category.name].push(subCategory.name);
          markedColumn[category.name].push(subCategory.name);
        }
      });
      column[category.name].push('Total');
      markedColumn[category.name].push('Total');
    }
  });

  return [
    sortObjectToMatch(column, desiredSortOrder),
    sortObjectToMatch(markedColumn, desiredSortOrder),
  ];
};

/**
 * Generates an object that represents every category and subcategory
 * that was specifically chosen by the user.
 *
 * @param {Object} selectedCustomCategories SubCategories chosen to be displayed.
 * @param {Object} categoryOptions Array describing all possible categories, and the subcategories contained within.
 * @param {Object} isUncategorizedSelected Boolean whether to add the Uncategorized category, as that is handled by separate logic.
 * @param {Array} desiredSortOrder Template object to match key order with.
 */
export const specificallyChosenCategoriesAsColumnForTaxPackage = (
  selectedCustomCategories,
  categoryOptions,
  isUncategorizedSelected,
  desiredSortOrder
) => {
  const column = [...categoryOptions];
  const processed = {};

  column.forEach((category) => {
    const subCategories = category.items.filter((subCategory) =>
      selectedCustomCategories.find((item) => item.id === subCategory.id)
    );
    if (subCategories.length > 0) {
      processed[category.title] = [...subCategories.map(({ name }) => name), 'Total'];
    }
  });

  if (isUncategorizedSelected) processed.Uncategorized = ['Uncategorized', 'Total'];

  return sortObjectToMatch(processed, desiredSortOrder);
};

/**
 * Generates an object that represents every category and subcategory
 * that was specifically chosen by the user.
 *
 * @param {Object} selectedCustomCategories SubCategories chosen to be displayed.
 * @param {Object} categoryOptions Array describing all possible categories, and the subcategories contained within.
 * @param {Object} isUncategorizedSelected Boolean whether to add the Uncategorized category, as that is handled by separate logic.
 * @param {Array} desiredSortOrder Template object to match key order with.
 */
export const specificallyChosenCategoriesAsColumn = (
  { cashFlow: { byCategory } },
  selectedCustomCategories,
  isUncategorizedSelected,
  desiredSortOrder
) => {
  const column = byCategory;
  const processed = {};
  const markedProcessed = {}; // marked headers for accordions
  column.forEach((category) => {
    const subCategories = category.subCategories.filter((subCategory) =>
      selectedCustomCategories.find((item) => item.name === subCategory.name)
    );
    if (subCategories.length > 0) {
      processed[category.name] = [];
      markedProcessed[category.name] = [];

      // Group subcategories by their parent categories if they have nested structure
      const subCategoriesByParent = {};
      subCategories.forEach((subCat) => {
        if (subCat.type) {
          if (!subCategoriesByParent[subCat.type]) {
            subCategoriesByParent[subCat.type] = [];
          }
          subCategoriesByParent[subCat.type].push(subCat);
        }
      });
      // Process each subcategory
      subCategories.forEach((subCategory) => {
        // If this is a parent category with children
        if (subCategoriesByParent[subCategory.type]) {
          // Add its children as marked subSubCategories
          subCategoriesByParent[subCategory.type].forEach((subSubCat) => {
            if (subSubCat.name === subCategory.name) {
              processed[category.name].push(subCategory.name);
              if (subSubCat.subCategories.length > 0) {
                markedProcessed[category.name].push(`${subCategory.name}+++`);
              } else {
                markedProcessed[category.name].push(subCategory.name);
              }
              subSubCat.subCategories.forEach((subSubSubCat) => {
                processed[category.name].push(subSubSubCat.name);
                markedProcessed[category.name].push(`___${subSubSubCat.name}`);
              });
            }
          });
        }
        // If this is a regular subcategory with no parent or children
        else if (!subCategory.type) {
          processed[category.name].push(subCategory.name);
          markedProcessed[category.name].push(subCategory.name);
        }
      });

      processed[category.name].push('Total');
      markedProcessed[category.name].push('Total');
    }
  });

  if (isUncategorizedSelected) {
    processed.Uncategorized = ['Uncategorized', 'Total'];
    markedProcessed.Uncategorized = ['Uncategorized', 'Total'];
  }

  return [
    sortObjectToMatch(processed, desiredSortOrder),
    sortObjectToMatch(markedProcessed, desiredSortOrder),
  ];
};

/**
 * Helper function to get the amount for a subcategory, nested subcategory, or total
 * @param {string} header The header name to look for
 * @param {Object} category The category object containing subCategories
 * @param {Array} subCategories The array of subcategories
 * @returns {number} The amount for the subcategory, nested subcategory, or total
 */
export const getSubCategoryAmount = (header, category, subCategories, columnTotal) => {
  if (header === 'Total') return columnTotal || category.amount || 0;

  // Check for nested subcategories first
  if (
    subCategories?.find((subCategory) =>
      subCategory?.subCategories?.find(({ name }) => name === header)
    )
  ) {
    let amount;
    subCategories?.forEach((subCategory) => {
      subCategory?.subCategories?.forEach((subSubCategory) => {
        if (subSubCategory.name === header) amount = subSubCategory.amount || 0;
      });
    });
    return amount;
  }

  // Check for regular subcategories
  if (!subCategories.find(({ name }) => name === header)) return 0;
  return subCategories.find(({ name }) => name === header).amount || 0;
};

/**
 * Processes and generates an object of all the data columns within the statement
 * for one category/section.
 * Note: any null values are replaced with a zero, the assumption being
 * that missing data means there were no applicable transactions.
 *
 * @param {boolean} isFirstSection Flag to identify the first row of data.
 * @param {string} title Title of the statement section category.
 * @param {Array} headers All applicable headers for the statement.
 * @param {Object} data Statment data.
 *
 * @return An array representing the columns and rows of data in the center of the statement.
 */
export const getDataColumns = (
  isFirstSection,
  title,
  headers,
  { cashFlow: { byCategoryMonth: columns } }
) => {
  return columns.map((column) => {
    const category = column?.categories?.find(({ name }) => name === title);
    const { subCategories } = category;
    if (isFirstSection)
      return [
        formatDate(column.date, 'MMM YYYY'),
        ...headers.map((header) => getSubCategoryAmount(header, category, subCategories)),
      ];
    return [...headers.map((header) => getSubCategoryAmount(header, category, subCategories))];
  });
};

export const getPropertyUnitDataColumns = (
  isFirstSection,
  title,
  headers,
  { cashFlow },
  dataType,
  cashFlowPropertiesData
) => {
  const { byCategoryProperty, byCategoryPropertyUnit } = cashFlow || {};
  const columns = byCategoryProperty || byCategoryPropertyUnit;
  return columns.map((column) => {
    const category = column?.categories?.find(({ name }) => name === title);
    const { subCategories } = category;
    if (isFirstSection) {
      let unit = null;
      const propertyUnitId = column?.propertyUnitId || column?.propertyUnitUniqueId?.split('_')[1];
      const propertyId = column?.propertyId || column?.propertyUnitUniqueId?.split('_')[0];
      const property =
        dataType === 'property' || !propertyUnitId
          ? cashFlowPropertiesData?.filter((p) => p?.id?.toString() === propertyId?.toString())[0]
          : cashFlowPropertiesData?.filter(
              (p) =>
                p?.units?.filter((u) => {
                  if (u?.id?.toString() === propertyUnitId?.toString()) {
                    unit = u;
                    return true;
                  }
                  return false;
                }).length > 0
            )[0];
      return [
        dataType === 'unit'
          ? `${property?.name}:::${unit?.name || ''}:::${column?.propertyUnitId?.toString()}`
          : `${property?.name}:::${column?.propertyId?.toString()}`,
        ...headers.map((header) => getSubCategoryAmount(header, category, subCategories)),
      ];
    }
    return [...headers.map((header) => getSubCategoryAmount(header, category, subCategories))];
  });
};

/**
 * Processes and generates an object of all the data columns within the statement
 * for one category/section. In this case it will dynamically calculate totals based
 * on the users's selection not based on what came back from the API call.
 *
 * Note: any null values are replaced with a zero, the assumption being
 * that missing data means there were no applicable transactions.
 *
 * @param {boolean} isFirstSection Flag to identify the first row of data.
 * @param {string} title Title of the statement section category.
 * @param {Array} headers All applicable headers for the statement.
 * @param {Object} data Statment data.
 *
 * @return An array representing the columns and rows of data in the center of the statement.
 */
export const calculateDataColumns = (
  isFirstSection,
  title,
  headers,
  { cashFlow: { byCategoryMonth: columns } }
) => {
  return columns.map((column) => {
    const category = column?.categories?.find(({ name }) => name === title);
    const { subCategories } = category;

    let columnTotal = 0;

    subCategories.forEach((subCategory) => {
      if (headers.includes(subCategory.name)) {
        columnTotal += subCategory.amount;
      }
    });
    if (isFirstSection)
      return [
        formatDate(column.date, 'MMM YYYY'),
        ...headers.map((header) =>
          getSubCategoryAmount(header, category, subCategories, columnTotal)
        ),
      ];
    return [
      ...headers.map((header) =>
        getSubCategoryAmount(header, category, subCategories, columnTotal)
      ),
    ];
  });
};

export const calculatePropertyUnitDataColumns = (
  isFirstSection,
  title,
  headers,
  { cashFlow },
  dataType,
  cashFlowPropertiesData
) => {
  const { byCategoryProperty, byCategoryPropertyUnit } = cashFlow || {};
  const columns = byCategoryProperty || byCategoryPropertyUnit;

  return columns.map((column) => {
    const category = column?.categories?.find(({ name }) => name === title);
    const { subCategories } = category;

    let columnTotal = 0;

    subCategories?.forEach((subCategory) => {
      if (headers.includes(subCategory.name)) {
        columnTotal += subCategory.amount;
      }
    });
    if (isFirstSection) {
      let unit = null;
      const property =
        dataType === 'property'
          ? cashFlowPropertiesData?.filter(
              (p) => p?.id.toString() === column?.propertyId?.toString()
            )[0]
          : cashFlowPropertiesData?.filter(
              (p) =>
                p.units.filter((u) => {
                  if (u?.id.toString() === column?.propertyUnitId?.toString()) {
                    unit = u;
                    return true;
                  }
                  return false;
                }).length > 0
            )[0];
      return [
        unit ? `${property?.name}:::${unit?.name}` : property?.name,
        ...headers.map((header) =>
          getSubCategoryAmount(header, category, subCategories, columnTotal)
        ),
      ];
    }
    return [
      ...headers.map((header) =>
        getSubCategoryAmount(header, category, subCategories, columnTotal)
      ),
    ];
  });
};

/**
 * Processes and generates an object of all the totals for a single section of the stament.
 * Note: any null values are replaced with a zero, the assumption being
 * that missing data means there were there were no applicable transactions.
 *
 * @param {string} title Title of the statement section/category.
 * @param {Array} headers The headers that apply to this section/category.
 * @param {Object} data Statment data.
 *
 * @return An array representing the totals column for a specific category/section.
 */
export const getTotalsColumn = (title, headers, { cashFlow: { byCategory: dataRows } }) => {
  const dataRow = dataRows.find(({ name }) => name === title);
  return headers.map((header) => {
    return getSubCategoryAmount(header, dataRow, dataRow.subCategories);
  });
};

/**
 * Processes and generates an object of all the totals for a single section of the statement.
 * In this case it will dynamically calculate totals based on the users's selection not based
 * on what came back from the API call.
 *
 * @param {string} title Title of the statement section/category.
 * @param {Array} headers The headers that apply to this section/category.
 * @param {Object} data Statment data.
 *
 * @return An array representing the totals column for a specific category/section.
 */
export const calculateTotalsColumn = (title, headers, { cashFlow: { byCategory: dataRows } }) => {
  const dataRow = dataRows.find(({ name }) => name === title);
  const { subCategories } = dataRow;
  let columnTotal = 0;

  return headers.map((header) => {
    if (header === 'Total') return columnTotal;
    let rowTotal = 0;
    const subCategory = subCategories.find((sub) => sub.name === header);
    if (subCategory) {
      rowTotal += subCategory.amount;
    }
    columnTotal += rowTotal;
    return rowTotal;
  });
};

/**
 * Calculates a row that contains the totals of all the column
 * values in the entire section.
 *
 * @param {Object} sections Precalculated sections data.
 *
 * @return An array representing the calculated totals of each column of the statement.
 */
export const calculateTotalsRow = (sections) => {
  const columnCount = sections[0]?.table?.dataColumns?.length || 0;
  const columnTotals = [];
  for (let col = 0; col < columnCount; col += 1) {
    const total = sections?.reduce((prev, section) => {
      const column = section.table.dataColumns[col];
      return prev + column[column.length - 1];
    }, 0);
    columnTotals.push(total);
  }
  return [...columnTotals];
};

/**
 * Processes and generates an array of arrays representing the three sum rows in the statement.
 * *
 * @param {Object} data Statment data.
 *
 * @return An array representing the totals column for a specific category/section.
 */
export const getDetailsColumns = ({ cashFlow: { byCategoryMonth: columns } }) => {
  const netOperatingIncome = [];
  const netOperatingCashflow = [];
  const totalInflowsOutflows = [];

  columns.forEach((column) => {
    netOperatingIncome.push(column?.cashFlowStatement?.netOperatingIncome);
    netOperatingCashflow.push(column?.cashFlowStatement?.netCashFlow);
    totalInflowsOutflows.push(column?.cashFlowStatement?.totalInflowOutflow);
  });

  return [netOperatingIncome, netOperatingCashflow, totalInflowsOutflows];
};

export const getPropertyDetailsColumns = ({ cashFlow }) => {
  const netOperatingIncome = [];
  const netOperatingCashflow = [];
  const totalInflowsOutflows = [];

  const { byCategoryProperty, byCategoryPropertyUnit } = cashFlow;
  const columns = byCategoryProperty || byCategoryPropertyUnit;

  columns.forEach((column) => {
    netOperatingIncome.push(column?.cashFlowStatement?.netOperatingIncome);
    netOperatingCashflow.push(column?.cashFlowStatement?.netCashFlow);
    totalInflowsOutflows.push(column?.cashFlowStatement?.totalInflowOutflow);
  });

  return [netOperatingIncome, netOperatingCashflow, totalInflowsOutflows];
};

/**
 * Removes dollar sign from currency formatted values.
 *
 * @param {string} value The value to process.
 *
 * @return The same value, without the dollar sign.
 */
export const stripDollars = (value: String) => value.split('$').join('');

/**
 * Converts an "accounting style" string value into a number,
 * using parentheses to determine if it should be a negative number.
 *
 * @param {string} value The value to process.
 *
 * @return The same value, as a Number.
 */
export const toNumber = (value: String) => {
  // remove commas from value
  const noCommasValue = value.split(',').join('');

  // default- positive value that needs no more processing
  let multiplier = 1;
  let processedValue = noCommasValue;

  // if it includes parentheses in the first and last position, it is a negative number
  if (
    processedValue.charAt(0) === '(' &&
    processedValue.charAt(processedValue.length - 1) === ')'
  ) {
    multiplier = -1;
    processedValue = noCommasValue.substring(1, noCommasValue.length - 1);
  }

  // convert to Number and return
  return Number(processedValue) * multiplier;
};

/**
 * Rotates the values in a 2 dimensional array 90 degress.
 *
 * @param {Array} columns An array of arrays to rotate.
 * @param {boolean} format Apply currency formatting to data or not.
 *
 * @return The same array of arrays, with values shifted 90 (rows become columns).
 */
export const transposeData = (columns, format = false) => {
  if (!columns) return [];
  const rowCount = columns[0]?.length;
  const rows = [];

  for (let i = 0; i < rowCount; i += 1) {
    rows[i] = [];
    for (let j = 0; j < columns?.length; j += 1) {
      rows[i][j] =
        format && isNumeric(columns[j][i]) ? formatCurrency(columns[j][i]).rounded : columns[j][i];
    }
  }

  return rows;
};

/**
 * Processes and generates the CSV- friendly version of the statement data.
 *
 * @param {Array} sections The statement data formatted for the UI.
 * @param {Array} netOperatingIncome The array representing the Net Operating Income row.
 * @param {Array} netOperatingCashflow The array representing the Net Operating Cashflow row.
 * @param {Array} totalInflowsOutflows representing the Total Inflows and Outflows row.
 *
 * @return An array of arrays representing the CSV-generation friendly version of the statement data.
 */
export const getStatementData = (
  sections: any,
  netOperatingIncome,
  netOperatingCashflow,
  totalInflowsOutflows,
  calculatedTotals,
  preset,
  dataType
) => {
  /** CSV Column Labels Row */

  const dataColumns = sections[0].table?.dataColumns?.map((col) => {
    const labelParts = col[0].split(':::');
    if (labelParts[1] && dataType !== 'property') {
      return labelParts?.slice(0, 2)?.join('-');
    }
    return labelParts[0];
  });
  const headerRow = ['Category', ...dataColumns, 'Total'];
  return [].concat?.apply(
    [headerRow],
    [
      ...sections.map((section, sectionIndex) => {
        const rows = [
          // row made of category title followed by empty cells
          [section?.title, ...Array((section?.table?.dataColumns?.length || 0) + 1).fill('')],
          ...transposeData(
            [
              // skip first row in first section, as those are headers (and are part of headerRow above )
              ...(section?.table?.dataColumns || []).map((col) =>
                sectionIndex === 0 ? col.slice(1) : col
              ),
            ],
            false
          ).map((row, rowIndex) => {
            // row made up of sub-category label, followed by data, followed by row total
            return [
              `${
                (section?.table?.unmarkedHeaderColumn || section?.table?.headerColumn || [])[
                  rowIndex
                ]
              }`,
              ...row.map((val) => formatCurrency(val).inDollars),
              formatCurrency(section?.table?.totalsColumn[rowIndex]).inDollars,
            ];
          }),
        ];

        if (
          section?.title === 'Operating Expenses' &&
          (preset === PRESETS.NET_OPERATING_CASHFLOW || preset === PRESETS.TOTAL_INFLOWS_OUTFLOWS)
        ) {
          rows.push([
            PRESETS.NOI.toUpperCase(),
            ...netOperatingIncome.map((val) => formatCurrency(val).inDollars),
            formatCurrency(sumOf(netOperatingIncome)).inDollars,
          ]);
        }

        if (section?.title === 'Uncategorized' && preset === PRESETS.TOTAL_INFLOWS_OUTFLOWS) {
          rows.push([
            PRESETS.NET_OPERATING_CASHFLOW.toUpperCase(),
            ...netOperatingCashflow.map((val) => formatCurrency(val).inDollars),
            formatCurrency(sumOf(netOperatingCashflow)).inDollars,
          ]);
        }

        if (sectionIndex === sections.length - 1) {
          if (preset === PRESETS.NOI)
            rows.push([
              PRESETS.NOI.toUpperCase(),
              ...netOperatingIncome.map((val) => formatCurrency(val).inDollars),
              formatCurrency(sumOf(netOperatingIncome)).inDollars,
            ]);
          if (preset === PRESETS.NET_OPERATING_CASHFLOW)
            rows.push([
              PRESETS.NET_OPERATING_CASHFLOW.toUpperCase(),
              ...netOperatingCashflow.map((val) => formatCurrency(val).inDollars),
              formatCurrency(sumOf(netOperatingCashflow)).inDollars,
            ]);
          if (preset === PRESETS.TOTAL_INFLOWS_OUTFLOWS)
            rows.push([
              PRESETS.TOTAL_INFLOWS_OUTFLOWS.toUpperCase(),
              ...totalInflowsOutflows.map((val) => formatCurrency(val).inDollars),
              formatCurrency(sumOf(totalInflowsOutflows)).inDollars,
            ]);
          if (preset === PRESETS.SCHEDULE_E_CATEGORIES)
            rows.push([
              PRESETS.SCHEDULE_E_CATEGORIES.toUpperCase(),
              ...calculatedTotals.map((val) => formatCurrency(val).inDollars),
              formatCurrency(sumOf(calculatedTotals)).inDollars,
            ]);
          if (preset === PRESETS.CUSTOM)
            rows.push([
              'TOTAL',
              ...calculatedTotals.map((val) => formatCurrency(val).inDollars),
              formatCurrency(sumOf(calculatedTotals)).inDollars,
            ]);
        }

        return rows;
      }),
    ]
  );
};

/**
 * Prepares a zero-filled array of arrays for when the data range selected does not contain any transactions.
 *
 * @param {Array} dateData The cashflow data that contains the dates selected by the user.
 * @param {Array} options The array of categoryOptions (used to get category list).
 *
 * @return An array of arrays representing the zero-filled represendation of the data.
 */
export const generateNullData = (dateData, options) => {
  const months = dateData?.cashFlow?.byMonth?.map((item) => formatDate(item?.date, 'MMM YYYY'));
  const categories = options?.map((option) => option?.title);

  return categories.map((title, index) => {
    return {
      title,
      table: {
        headerColumn: ['Total'],
        dataColumns: months?.map((month) => (index === 0 ? [month, 0] : [0])),
        totalsColumn: [0],
      },
    };
  });
};

export const generatePropertyNullData = (dateData, options) => {
  const properties = dateData?.cashFlow?.byProperty?.map((item) => item?.propertyId);
  const categories = options?.map((option) => option?.title);

  return categories.map((title, index) => {
    return {
      title,
      table: {
        headerColumn: ['Total'],
        dataColumns: properties?.map((property) => (index === 0 ? [property, 0] : [0])),
        totalsColumn: [0],
      },
    };
  });
};

/**
 * Finds the parent category name of a subSubCategory by looking backwards through the array
 * until finding the first item marked with +++
 * @param {Array} sectionHeaders Array of headers for the section
 * @param {number} currentIndex Index of the current subSubCategory
 * @returns {string} The parent category name without the +++ marker, or null if not found
 */
export const findParentCategory = (sectionHeaders, currentIndex) => {
  // Take the array up to current index and reverse it to look backwards
  const previousHeaders = sectionHeaders.slice(0, currentIndex + 1).reverse();

  // Find first item with +++ which will be the parent
  const parentHeader = previousHeaders.find((header) => header.includes('+++'));

  // Return the parent name without the +++ marker, or null if not found
  return parentHeader ? parentHeader.replace('+++', '') : null;
};
